module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/layout.js"},
    },{
      plugin: require('../plugins/gatsby-plugin-auth0/gatsby-browser.js'),
      options: {"plugins":[],"domain":"aceorderlive.eu.auth0.com","clientId":"06vsMQoK6mSgKX16EjgxpCCh6earRbNA","useRefreshTokens":true,"cacheLocation":"localstorage","scope":"openid profile email update:current_user_metadata read:current_user","audience":"https://aceorderlive.eu.auth0.com/api/v2/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-goatcounter/gatsby-browser.js'),
      options: {"plugins":[],"code":"ace-my-order","pixel":false},
    }]
